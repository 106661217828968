<template>
  <div class="network-list--root">
    <b-loading :is-full-page="true" v-model="gridLoading"></b-loading>

    <template>
      <div class="network-list--header">
        <b-input
          size="is-medium"
          placeholder="Search client"
          v-model="gridSearchCriteria"
          icon-pack="fas"
          icon="search"
          icon-clickable
          @icon-click="searchNetworks"
          @input="searchNetworks"
        ></b-input>
        <router-link to="/networks/config">
          Create new client
          <img
            src="../../assets/add_button.svg"
            alt="Plus icon inside a square"
          />
        </router-link>
      </div>

      <b-field class="network-list--results" v-if="isSearchNetworks">
        <span>{{ networkConfigList.length }} {{ networkConfigList.length === 1 ? 'result' : 'results' }} found</span>
      </b-field>

      <b-table
        ref="gridTable"
        class="network-list--table"
        :class="{ 'is-empty': !networkConfigList || networkConfigList.length === 0 }"
        :data="networkConfigList.slice(firstIndex, lastIndex)"
        :height="600"
        default-sort="clientName"
        sort-icon="plus"
        sticky-header
        bordered
        @sort="onSort"
      >
        <b-table-column
          sortable
          field="clientName"
          label="Client Name"
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
            <SortIcons
              :field="column.field"
              :sortedField="sortedField"
              :isSortDescending="isSortDescending"
            />
          </template>
          <template v-slot="props">
            <span>
              {{ getFormattedString(props.row.clientName) }}
            </span>
          </template>
        </b-table-column>

        <b-table-column
          sortable
          field="clientId"
          label="Client ID"
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
            <SortIcons
              :field="column.field"
              :sortedField="sortedField"
              :isSortDescending="isSortDescending"
            />
          </template>
          <template v-slot="props">
            <span>
              {{ getFormattedString(props.row.clientId) }}
            </span>
          </template>
        </b-table-column>

        <b-table-column
          sortable
          field="oeUrlName"
          label="Pre-Enrollment URL name"
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
            <SortIcons
              :field="column.field"
              :sortedField="sortedField"
              :isSortDescending="isSortDescending"
            />
          </template>
          <template v-slot="props">
            <span>
              {{ getFormattedString(props.row.oeUrlName) }}
            </span>
          </template>
        </b-table-column>

        <b-table-column sortable field="status" label="Status">
          <template v-slot:header="{ column }">
            {{ column.label }}
            <SortIcons
              :field="column.field"
              :sortedField="sortedField"
              :isSortDescending="isSortDescending"
            />
          </template>
          <template v-slot="props">
            <span :class="`status-text ${props.row.status}`">
              {{ getFormattedString(props.row.status) }}
            </span>
          </template>
        </b-table-column>

        <b-table-column label="Edit" v-slot="props" width="150">
          <b-field class="column-icons">
            <router-link
              data-hover="Edit"
              style="cursor: pointer"
              :to="{ name: 'ClientConfig', params: { clientName:  props.row.clientName } }"
            >
              <img src="../../assets/edit_button.svg" alt="Edit plan" />
            </router-link>
          </b-field>
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import store from '@/store/store'
import _ from 'lodash'
import Vue from 'vue'

export default {
  name: 'network-list',
  components: {
    SortIcons: Vue.component('SortIcons', {
      props: {
        field: {
          type: String,
          required: true
        },
        sortedField: {
          type: String,
          required: false
        },
        isSortDescending: {
          type: Boolean,
          required: true
        }
      },
      template: `
        <span>
          <span v-show="field !== sortedField"> <i class="fas fa-sort" /> </span>
          <span v-show="field === sortedField && isSortDescending"> <i class="fas fa-sort-down" /> </span>
          <span v-show="field === sortedField && !isSortDescending"> <i class="fas fa-sort-up" /> </span>
        </span>
      `
    })
  },

  mounted () {
    const tableScrollBody = this.$el.getElementsByClassName('table-wrapper')[0]
    tableScrollBody.addEventListener('scroll', this.onBodyScroll)
  },

  beforeDestroy () {
    /* Clean up just to be sure */
    const tableScrollBody = this.$el.getElementsByClassName('table-wrapper')[0]
    tableScrollBody.removeEventListener('scroll', this.onBodyScroll)
  },

  data () {
    this.getNetworkConfigList()

    return {
      networkConfigList: [],
      firstIndex: 0,
      lastIndex: 15,
      oldScrollValue: 0,
      gridLoading: true,
      gridSearchCriteria: '',
      rowData: null,
      sortedField: '',
      isSortDescending: false,
      isSearchNetworks: false
    }
  },

  methods: {
    onBodyScroll: _.debounce(function (event) {
      if (
        event.target.scrollTop + event.target.clientHeight + 500 >=
          event.target.scrollHeight &&
        this.networkConfigList.length >= this.lastIndex
      ) {
        this.lastIndex += 5
      }
    }, 100),

    searchNetworks: _.debounce(function () {
      const lowerSearchCriteria = this.gridSearchCriteria.toLowerCase()
      const storeNetworkConfigList = this.getNetworkConfigListFromStore()

      if (lowerSearchCriteria) {
        this.networkConfigList = storeNetworkConfigList.filter(
          (client) =>
            client.clientName.toLowerCase().includes(lowerSearchCriteria) ||
            client.oeUrlName.toLowerCase().includes(lowerSearchCriteria)
        )
      } else {
        this.networkConfigList = storeNetworkConfigList ?? []
      }

      this.isSearchNetworks = !!lowerSearchCriteria
    }, 400),

    getFormattedString: (value) => value ?? '',

    getNetworkConfigList () {
      store
        .dispatch('networkConfig/getClientConfigs')
        .then(() => {
          this.networkConfigList = this.getNetworkConfigListFromStore() ?? []
        })
        .catch(console.error)
        .finally(() => { this.gridLoading = false })
    },

    getNetworkConfigListFromStore () {
      return store.getters['networkConfig/getClientConfigGrid']
    },

    onSort (event) {
      if (this.sortedField === event) {
        if (this.isSortDescending) {
          this.sortedField = null

          return
        }

        this.isSortDescending = !this.isSortDescending
      } else this.isSortDescending = false
      this.sortedField = event
    }
  },
  created () {
    store.dispatch('plans/getAllPlans')
  }
}
</script>

<style lang="scss">
@import "./style.scss";
</style>
